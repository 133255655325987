import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,922.000000) scale(0.100000,-0.100000)">
          <path d="M3650 7910 c-208 -22 -377 -52 -475 -85 -22 -8 -74 -24 -115 -36 -41
-12 -125 -44 -187 -70 -61 -27 -116 -49 -121 -49 -5 0 -15 -7 -22 -14 -6 -8
-28 -20 -48 -27 -20 -6 -51 -22 -69 -35 -17 -13 -35 -24 -39 -24 -10 0 -103
-59 -140 -89 -18 -15 -61 -44 -98 -66 -36 -22 -66 -43 -66 -48 0 -4 -13 -15
-29 -25 -56 -34 -300 -264 -340 -321 -12 -16 -35 -44 -53 -63 -65 -68 -168
-201 -168 -217 0 -5 -7 -14 -16 -22 -22 -18 -104 -147 -104 -163 0 -7 -7 -19
-15 -26 -8 -7 -15 -17 -15 -21 0 -5 -16 -32 -35 -60 -19 -28 -35 -58 -35 -66
0 -8 -8 -27 -19 -41 -10 -15 -31 -56 -46 -92 -14 -36 -37 -86 -51 -112 -13
-26 -24 -56 -24 -67 0 -11 -4 -22 -9 -25 -4 -3 -11 -23 -14 -43 -4 -21 -16
-55 -27 -75 -11 -21 -20 -49 -20 -62 0 -13 -6 -39 -14 -57 -7 -19 -19 -63 -25
-99 -7 -36 -17 -74 -21 -85 -16 -38 -42 -180 -50 -280 -5 -55 -11 -110 -14
-121 -20 -91 -15 -490 9 -689 9 -77 27 -186 39 -240 49 -216 81 -332 97 -350
5 -5 9 -18 9 -30 0 -12 4 -25 8 -30 5 -6 19 -41 32 -80 13 -38 29 -79 37 -91
7 -11 27 -59 44 -105 17 -46 36 -91 43 -99 7 -8 28 -44 47 -80 19 -36 39 -71
46 -77 6 -7 24 -39 39 -70 16 -32 32 -61 36 -64 9 -6 98 -139 98 -147 0 -3 10
-14 23 -26 12 -11 38 -43 56 -70 75 -109 364 -394 471 -464 30 -20 87 -61 126
-92 39 -30 86 -64 105 -75 80 -47 168 -95 219 -120 30 -14 82 -39 115 -55 33
-16 98 -43 145 -60 47 -17 94 -36 105 -43 26 -16 750 -23 740 -7 -3 5 -27 10
-53 10 -26 0 -92 12 -147 26 -55 14 -122 29 -150 33 -27 4 -59 12 -70 18 -30
16 -67 31 -95 38 -14 4 -40 13 -58 21 -18 9 -41 17 -52 19 -10 2 -28 9 -40 15
-12 6 -33 14 -48 16 -14 3 -45 14 -68 24 -24 11 -48 20 -54 20 -5 0 -18 4 -28
9 -9 5 -69 35 -132 66 -63 31 -122 62 -130 69 -8 7 -46 30 -85 52 -38 21 -72
41 -75 45 -3 4 -17 13 -30 19 -26 11 -83 55 -169 130 -133 116 -198 176 -208
194 -7 12 -31 39 -142 161 -83 90 -189 238 -241 337 -14 26 -32 56 -40 65 -9
10 -25 41 -36 68 -12 28 -35 72 -53 99 -17 28 -31 56 -31 63 0 8 -12 37 -27
66 -79 156 -154 422 -203 722 -29 175 -37 581 -15 770 27 235 45 350 63 395 6
14 16 54 23 90 7 36 18 74 26 85 7 11 13 28 13 38 0 25 58 195 79 232 9 17 28
59 41 95 13 36 28 72 35 80 6 8 26 44 44 79 18 34 50 91 71 125 22 33 51 82
66 109 15 26 31 47 36 47 4 0 8 6 8 14 0 8 6 21 13 28 7 7 26 31 42 53 16 22
47 61 70 86 22 25 57 66 79 90 72 83 127 139 135 139 4 0 17 10 28 23 12 12
32 32 44 44 13 12 32 30 41 40 9 10 36 33 60 51 24 18 80 62 125 97 45 36 90
65 99 65 10 0 30 12 45 26 30 29 54 44 109 70 19 9 39 22 45 28 5 7 30 19 55
26 25 8 47 17 50 20 3 4 26 16 53 28 26 12 66 31 90 42 24 11 47 20 51 20 4 0
33 12 64 26 83 39 86 40 217 83 192 63 526 127 572 110 12 -4 23 -4 25 1 2 5
52 12 112 16 100 7 109 6 103 -10 -9 -22 15 -31 44 -15 17 9 24 9 27 0 2 -6
12 -11 22 -11 10 0 22 -5 25 -11 4 -6 13 -8 19 -6 7 3 19 1 27 -3 18 -12 -13
-11 -114 3 -57 8 -76 14 -73 24 6 16 -39 18 -49 3 -3 -6 -18 -7 -33 -3 -24 6
-26 5 -13 -10 7 -10 22 -17 33 -17 16 0 18 -3 9 -14 -7 -8 -21 -12 -32 -9 -11
3 -17 8 -15 11 10 9 -47 32 -66 26 -24 -7 -14 -24 15 -24 22 -1 22 -1 3 -15
-18 -14 -16 -15 30 -15 27 0 87 -6 134 -14 47 -8 141 -22 210 -31 113 -15 311
-66 335 -87 5 -4 16 -8 24 -8 17 0 122 -40 196 -75 28 -13 66 -31 85 -40 19
-8 53 -27 75 -40 22 -13 60 -36 85 -50 59 -33 81 -47 98 -62 7 -7 19 -13 25
-13 7 0 24 -13 39 -28 14 -16 45 -34 68 -42 32 -10 64 -37 131 -109 83 -89 90
-94 102 -77 13 18 16 18 52 -15 45 -40 99 -122 109 -164 7 -27 21 -44 37 -45
4 0 10 11 13 25 9 34 19 32 61 -17 48 -56 74 -114 80 -179 6 -72 20 -82 41
-30 24 61 36 56 90 -44 35 -63 45 -116 35 -171 -7 -35 -6 -53 3 -66 12 -16 16
-12 35 42 14 39 28 60 38 60 15 0 15 2 1 18 -9 9 -29 41 -44 70 -51 94 -116
193 -139 212 -8 7 -15 17 -15 22 0 14 -70 103 -143 181 -21 22 -45 53 -55 69
-9 15 -22 28 -29 28 -7 0 -13 5 -13 10 0 12 -38 50 -121 122 -30 25 -75 67
-102 92 -26 25 -54 46 -63 46 -11 0 -13 6 -9 19 3 11 1 22 -5 26 -5 3 -10 1
-10 -6 0 -7 -9 -3 -20 9 -12 12 -27 22 -35 22 -8 0 -15 5 -15 12 0 18 -30 47
-44 42 -7 -3 -31 13 -55 35 -24 22 -49 37 -57 34 -8 -3 -18 5 -25 21 -7 14
-19 26 -27 26 -12 0 -12 -2 -1 -17 12 -15 12 -16 -6 -9 -11 4 -32 13 -47 18
-18 6 -28 17 -28 30 0 16 -5 19 -28 13 -25 -5 -29 -2 -41 30 -7 19 -17 35 -23
35 -6 0 -5 -6 2 -15 9 -11 10 -19 3 -26 -14 -14 -43 0 -43 21 0 11 -5 22 -11
26 -8 4 -9 0 -4 -15 4 -15 2 -21 -8 -21 -8 0 -17 9 -20 20 -3 11 -12 20 -21
20 -9 0 -16 6 -16 14 0 9 -8 12 -25 8 -14 -2 -25 0 -25 6 0 14 -27 23 -46 15
-14 -5 -12 -8 7 -19 23 -13 22 -13 -11 -8 -60 10 -68 14 -47 25 28 16 19 30
-20 30 -71 -1 -138 11 -149 27 -6 8 -16 12 -22 8 -18 -11 -14 -23 10 -29 12
-4 19 -9 16 -13 -4 -3 -19 -4 -33 0 -21 5 -24 10 -15 25 13 25 -4 31 -81 31
-56 0 -60 -2 -54 -20 8 -26 -11 -26 -25 -1 -7 14 -29 22 -78 31 -104 17 -110
30 -14 29 65 -1 85 -4 89 -16 5 -12 9 -10 19 8 15 29 4 39 -42 39 -17 0 -49 6
-71 14 -22 8 -57 17 -79 20 -57 10 -488 14 -564 6z m877 -156 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m143 -20 c0 -11 -5 -14 -16 -10 -9 3
-18 6 -20 6 -3 0 -2 5 1 10 10 16 35 12 35 -6z"/>
<path d="M4450 7834 c0 -17 14 -18 28 -1 8 10 6 13 -9 14 -10 1 -19 -5 -19
-13z"/>
<path d="M3815 7770 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M3907 7774 c-8 -8 1 -24 13 -24 4 0 10 7 14 15 5 15 -14 21 -27 9z"/>
<path d="M3712 7739 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M3790 7729 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3596 7565 l-69 -36 -19 21 c-15 16 -31 20 -77 20 -55 0 -64 -3 -121
-46 -60 -46 -62 -46 -99 -34 -21 7 -53 10 -72 7 -41 -5 -152 -79 -181 -120
-18 -25 -26 -28 -57 -24 -28 3 -47 -2 -85 -25 -27 -16 -55 -35 -62 -41 -19
-17 -93 -81 -135 -116 -20 -17 -40 -31 -44 -31 -9 0 -89 -76 -144 -134 -20
-22 -67 -72 -106 -112 -38 -40 -81 -89 -95 -109 -14 -20 -33 -44 -42 -53 -10
-9 -18 -22 -18 -28 0 -6 -20 -36 -45 -66 -25 -30 -45 -59 -45 -65 0 -5 -9 -18
-20 -28 -11 -10 -20 -22 -20 -27 0 -5 -23 -51 -52 -101 -79 -141 -126 -237
-144 -292 -14 -45 -23 -68 -56 -145 -6 -14 -17 -38 -24 -55 -7 -16 -17 -50
-24 -75 -6 -25 -15 -58 -20 -75 -5 -16 -11 -50 -15 -75 -3 -25 -10 -54 -15
-65 -21 -48 -52 -221 -63 -350 -7 -77 -11 -268 -10 -425 2 -234 6 -304 22
-390 11 -58 24 -140 30 -182 6 -43 28 -133 50 -200 21 -68 47 -148 56 -178 22
-74 83 -216 112 -262 12 -21 23 -40 23 -43 0 -8 39 -97 50 -115 13 -21 83
-138 96 -161 6 -11 20 -30 31 -42 11 -12 23 -30 26 -39 4 -10 11 -18 16 -18 5
0 13 -9 16 -21 8 -25 41 -75 70 -106 11 -11 33 -36 48 -54 36 -44 49 -57 88
-95 61 -59 77 -76 104 -109 37 -46 41 -49 104 -104 53 -46 73 -59 127 -84 17
-8 44 -31 61 -51 34 -42 110 -95 195 -136 44 -21 67 -27 100 -23 43 5 99 -9
97 -24 -7 -40 3 -59 39 -74 108 -46 229 -49 318 -7 27 13 51 26 54 29 3 3 14
11 25 17 18 11 18 11 -9 12 -51 0 -106 22 -106 41 0 31 146 169 238 225 l34
21 -59 17 c-32 10 -75 24 -96 32 -53 20 -54 19 -53 -30 1 -54 -20 -86 -90
-136 -45 -32 -55 -35 -136 -38 -79 -4 -89 -2 -124 22 -21 14 -47 26 -58 26
-20 0 -59 38 -51 50 10 16 129 92 195 126 38 19 82 34 99 34 42 0 39 14 -6 34
-21 9 -65 32 -98 51 -70 40 -89 44 -80 15 4 -13 -3 -36 -19 -65 -36 -61 -114
-99 -189 -93 -97 8 -276 118 -248 151 31 39 269 115 319 102 35 -9 26 12 -16
39 -22 13 -45 30 -53 38 -8 7 -37 30 -64 51 l-50 38 -6 -32 c-11 -52 -41 -82
-105 -106 -59 -21 -82 -21 -139 0 -43 16 -123 76 -174 132 -38 41 -41 49 -29
62 25 24 137 45 245 45 l103 1 -21 28 c-11 16 -26 32 -33 36 -6 5 -32 34 -56
66 -25 31 -48 57 -52 57 -4 0 -10 -10 -13 -22 -18 -59 -84 -98 -165 -98 -53 0
-55 1 -131 72 -74 70 -151 180 -136 195 11 10 229 2 287 -11 36 -7 67 -12 68
-11 2 2 -15 31 -37 65 -23 34 -41 65 -41 68 0 4 -7 18 -16 31 l-16 23 -27 -26
c-69 -66 -172 -70 -240 -7 -59 55 -161 219 -161 259 0 23 99 9 222 -32 59 -20
113 -36 122 -36 30 0 27 22 -6 53 -24 22 -40 51 -52 92 -10 33 -20 67 -22 75
-4 11 -13 6 -34 -20 -28 -34 -30 -35 -104 -35 -69 0 -77 2 -106 30 -37 36 -76
104 -115 200 -16 39 -32 74 -36 78 -14 18 3 47 28 47 29 0 147 -50 213 -91 26
-16 55 -29 64 -29 19 0 21 35 6 90 -5 19 -11 48 -14 64 -7 38 -13 42 -34 23
-13 -12 -37 -17 -78 -17 -54 0 -63 3 -106 40 -38 33 -53 56 -78 122 -18 47
-34 109 -37 148 -5 63 -4 68 15 74 28 9 85 -24 179 -104 40 -33 77 -60 82 -60
12 0 14 72 5 144 l-7 46 -51 1 c-68 1 -91 9 -126 43 -50 47 -72 99 -90 216
-11 66 4 140 28 140 41 0 129 -73 195 -162 42 -57 51 -50 44 35 -7 92 -23 124
-63 132 -75 14 -134 100 -142 206 -5 77 14 198 34 206 11 4 33 -11 99 -72 20
-18 65 -82 90 -129 5 -10 14 -17 19 -15 12 5 19 59 10 79 -12 26 -13 42 -4 65
8 21 4 26 -28 43 -22 11 -53 42 -76 75 -51 73 -57 132 -26 234 25 83 41 109
63 100 25 -9 138 -184 144 -223 9 -55 27 -41 20 16 -3 27 -2 52 4 55 5 4 9 15
9 25 0 10 7 23 16 28 13 8 10 13 -21 32 -79 49 -120 125 -110 202 6 43 71 187
95 212 5 5 19 -11 32 -37 13 -24 38 -75 57 -111 18 -36 40 -66 47 -66 8 0 25
18 39 40 19 29 32 40 50 40 23 0 24 2 15 20 -6 11 -15 18 -20 15 -17 -11 -70
25 -97 66 -39 60 -38 125 5 207 43 83 73 123 90 120 15 -3 55 -109 73 -195 6
-29 13 -53 16 -53 3 0 18 9 33 20 19 14 30 16 32 8 3 -8 9 -8 24 0 17 9 17 11
3 17 -21 8 -13 45 10 46 13 0 14 2 1 6 -39 15 -109 113 -112 156 -3 39 34 120
86 187 27 36 51 66 52 68 1 2 11 -1 22 -7 17 -9 21 -26 28 -118 4 -59 6 -114
5 -121 -7 -42 63 -56 106 -22 14 11 32 20 39 20 8 0 14 5 14 10 0 6 -4 10 -10
10 -5 0 -10 13 -10 30 0 22 -7 33 -27 44 -26 13 -52 52 -67 99 -3 10 -10 16
-15 12 -5 -3 -12 -1 -16 5 -3 5 -2 10 3 10 5 0 21 24 35 53 27 55 99 134 142
157 49 26 57 -9 36 -173 -7 -48 -9 -92 -6 -97 8 -13 96 -13 130 0 52 20 55 30
22 90 -44 82 -66 109 -92 116 -17 4 -24 14 -27 37 -3 18 -8 40 -13 50 -5 13
-2 17 14 17 16 0 21 -6 21 -25 0 -34 12 -31 35 8 23 39 45 62 107 111 51 41
81 44 86 8 4 -23 4 -23 25 -5 12 10 19 14 14 9 -4 -5 0 -20 8 -33 20 -30 11
-47 -16 -32 -12 6 -22 9 -23 7 -7 -11 -51 -155 -51 -167 0 -9 28 -22 77 -36
l76 -22 21 28 c19 25 20 34 11 74 -6 26 -17 57 -26 69 -30 47 -33 80 -11 127
11 24 29 51 41 59 43 32 136 78 141 70 11 -18 18 -130 8 -130 -11 0 -73 -95
-73 -111 0 -5 35 -25 77 -45 68 -31 79 -33 89 -19 6 8 17 15 24 15 8 0 10 6 5
18 -4 9 -11 38 -16 64 -4 26 -12 56 -18 67 -39 71 14 152 124 190 37 12 71 19
76 14 16 -16 -50 -183 -73 -183 -4 0 -8 -9 -8 -19 0 -20 50 -67 97 -92 22 -12
26 -11 34 4 6 9 16 17 23 17 10 0 12 14 8 53 -2 28 -8 60 -13 69 -5 9 -9 37
-9 61 0 31 -6 49 -19 61 -11 10 -23 31 -26 47 -8 36 -36 56 -90 64 -34 5 -51
1 -109 -30z"/>
<path d="M4626 7465 c-14 -15 -28 -36 -31 -46 -6 -17 -17 -19 -91 -19 -80 0
-87 -2 -110 -27 -21 -23 -25 -35 -20 -63 6 -41 53 -86 106 -100 29 -8 115 -70
97 -70 -3 0 -1 -13 4 -29 14 -40 46 -53 131 -55 73 -1 75 -2 107 -38 33 -38
39 -40 144 -53 37 -5 50 -11 56 -28 23 -58 135 -105 203 -86 26 7 56 -10 31
-18 -19 -7 4 -77 33 -99 30 -25 121 -57 135 -48 12 7 35 -17 43 -46 16 -55 29
-80 42 -80 8 0 14 -4 14 -9 0 -5 10 -13 23 -16 12 -4 37 -14 55 -22 29 -14 35
-14 56 0 17 12 26 13 35 4 15 -15 14 -6 -5 32 -9 17 -14 38 -11 45 9 22 73 46
126 46 53 0 56 4 45 48 -5 16 -14 22 -34 22 -29 0 -43 19 -34 45 5 11 -1 15
-19 15 -17 0 -28 -7 -32 -20 -3 -10 -20 -31 -37 -45 -29 -25 -35 -26 -97 -19
-72 7 -101 28 -101 70 0 35 63 74 121 74 25 0 48 3 51 6 8 7 -28 89 -39 90
-21 2 -50 19 -64 37 -16 20 -17 19 -44 -26 -15 -26 -40 -57 -57 -68 -28 -21
-34 -21 -99 -9 -92 16 -102 33 -53 89 32 36 42 41 79 41 62 0 73 18 41 69 -14
22 -26 46 -26 52 0 7 -9 9 -24 5 -15 -4 -28 0 -36 9 -11 14 -15 14 -31 -1 -11
-10 -19 -30 -19 -47 0 -20 -11 -40 -34 -63 -29 -29 -41 -34 -80 -34 -67 0
-133 40 -108 65 4 3 7 14 7 23 0 9 6 16 13 14 7 -1 15 4 18 12 3 7 13 12 23 9
26 -7 91 23 91 42 0 8 -16 38 -35 65 -19 27 -35 55 -35 61 0 8 -4 8 -14 0 -11
-9 -15 -9 -19 1 -4 9 -6 10 -6 1 -1 -7 -7 -10 -14 -8 -13 6 -42 -70 -32 -85 9
-14 -22 -73 -46 -88 -53 -34 -176 -22 -162 15 13 36 53 78 84 88 51 17 59 39
27 72 -15 15 -30 40 -33 56 -4 15 -10 27 -14 27 -4 0 -13 12 -20 27 -9 20 -15
23 -23 15 -20 -20 -38 -13 -96 37 -74 64 -120 75 -156 36z m215 -135 c24 -13
52 -67 45 -86 -3 -8 -13 -14 -23 -14 -15 0 -16 -2 -3 -10 11 -7 5 -10 -24 -10
-23 0 -64 12 -102 31 -102 50 -107 72 -23 88 62 12 107 13 130 1z"/>
<path d="M3988 7387 c-49 -28 -52 -62 -7 -72 36 -8 58 14 57 58 -1 37 -7 39
-50 14z"/>
<path d="M2210 6950 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2180 6915 c0 -9 6 -12 15 -9 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0
-15 -7 -15 -15z"/>
<path d="M2043 6743 c-13 -2 -23 -8 -23 -13 0 -5 -15 -25 -34 -45 -19 -20 -32
-43 -29 -50 2 -8 -5 -28 -16 -44 -12 -16 -21 -35 -21 -43 0 -20 65 55 96 111
14 28 35 59 46 71 10 11 15 19 11 19 -4 -1 -18 -4 -30 -6z"/>
<path d="M3038 6613 c-13 -14 -18 -36 -18 -76 l0 -57 100 -111 c70 -78 100
-119 101 -138 0 -14 0 -421 -1 -904 l-2 -877 -56 -53 c-135 -124 -142 -132
-142 -169 0 -54 18 -95 48 -107 20 -8 100 -7 312 4 547 29 695 38 715 45 11 4
24 8 29 9 4 0 8 -160 7 -357 l-1 -357 -43 -40 c-24 -22 -64 -59 -90 -81 -44
-39 -47 -44 -47 -91 0 -86 26 -95 194 -69 50 8 147 22 216 31 406 52 470 62
600 91 227 53 380 128 506 250 102 99 160 200 210 369 24 80 27 111 31 280 8
279 -22 424 -117 569 -97 149 -240 235 -475 287 -75 16 -341 36 -533 39 -18 0
-30 15 -59 75 -43 87 -47 79 73 171 71 54 80 58 217 91 78 20 148 41 155 47
17 17 25 87 13 119 -6 14 -18 30 -28 35 -10 6 -131 17 -270 26 -346 23 -350
23 -368 0 -25 -35 -155 -291 -155 -307 0 -9 7 -20 15 -24 11 -7 3 -18 -39 -51
l-54 -42 -5 233 c-3 163 -15 341 -41 601 -20 203 -39 379 -42 392 -12 47 -32
54 -265 85 -123 16 -289 39 -369 50 -80 11 -181 23 -225 26 -71 5 -82 3 -97
-14z m507 -111 l370 -47 3 -710 c1 -394 6 -713 11 -718 12 -12 36 2 154 93 56
43 199 153 317 245 118 91 216 170 218 174 2 5 -41 12 -95 16 -54 3 -109 7
-123 8 -18 1 -26 9 -28 25 -4 27 -4 27 273 12 266 -15 277 -16 273 -43 -3 -21
-7 -22 -98 -19 l-95 3 -70 -56 c-38 -31 -83 -67 -100 -81 -16 -13 -58 -46 -92
-72 -34 -26 -62 -53 -63 -60 0 -8 33 -78 74 -155 40 -78 135 -263 211 -412
204 -400 188 -375 241 -375 31 0 46 -5 51 -16 13 -34 0 -36 -387 -54 -118 -5
-257 -12 -307 -15 -93 -5 -93 -5 -93 19 0 27 21 36 88 36 23 0 42 3 42 6 0 6
-328 653 -336 662 -1 2 -16 1 -33 -3 l-31 -7 2 -336 3 -337 70 0 c68 0 70 -1
70 -25 l0 -25 -330 -16 c-181 -9 -397 -20 -478 -24 l-149 -8 4 29 3 29 88 3
c84 3 88 4 97 28 6 16 9 432 8 1112 l-3 1087 -45 6 c-25 4 -71 10 -102 14 -52
6 -58 9 -58 29 0 31 7 35 43 29 18 -2 199 -26 402 -51z m1488 -1488 c436 -60
626 -288 627 -750 0 -218 -28 -355 -102 -494 -107 -201 -286 -324 -578 -398
-99 -26 -168 -37 -455 -72 -132 -17 -292 -37 -356 -45 -152 -20 -151 -20 -147
8 3 20 11 23 88 32 l85 10 5 435 5 435 115 6 c63 4 174 8 245 10 l130 4 3
-413 2 -414 53 7 c72 9 162 32 204 52 56 26 137 107 171 170 40 75 70 192 83
323 16 153 6 633 -16 730 -34 156 -108 261 -214 301 -59 22 -237 46 -265 36
-13 -5 -16 -21 -16 -79 0 -42 -4 -68 -9 -62 -15 15 -91 165 -91 178 0 17 287
10 433 -10z m67 -270 c0 -12 -11 -55 -24 -95 -13 -41 -36 -114 -51 -164 -15
-49 -30 -84 -33 -76 -3 11 59 329 75 381 4 12 7 12 19 -4 8 -11 14 -30 14 -42z
m68 -446 l-3 -61 -82 -19 -83 -20 0 -48 c0 -52 0 -51 138 -199 8 -8 12 -33 10
-67 -5 -86 -9 -83 -105 99 -32 60 -63 87 -63 55 0 -6 -7 -19 -16 -29 -15 -16
-14 -35 5 -233 28 -288 31 -262 -31 -291 -51 -24 -125 -44 -160 -45 -16 0 -18
11 -18 88 0 73 6 106 34 193 99 305 113 367 85 385 -6 3 -35 -18 -65 -47 l-55
-54 3 60 3 59 50 14 c59 17 75 28 75 52 0 14 11 18 57 22 46 4 61 10 80 33 31
35 124 115 135 115 5 0 8 -28 6 -62z"/>
<path d="M5916 6552 c-15 -46 -104 -75 -161 -53 -12 5 -25 3 -33 -6 -11 -10
-17 -11 -28 -2 -8 6 -14 8 -14 4 0 -4 8 -13 17 -20 15 -11 15 -13 -5 -19 -28
-9 -28 -22 -1 -61 30 -44 82 -75 125 -75 32 0 35 2 29 23 -4 12 -11 26 -16 32
-33 35 20 66 121 71 30 1 57 3 59 3 2 1 -9 11 -24 23 -15 12 -35 39 -45 61
-16 34 -19 37 -24 19z"/>
<path d="M2610 6540 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M1821 6437 c-23 -11 -71 -100 -71 -134 0 -10 -8 -48 -18 -85 -15 -54
-27 -76 -64 -114 -29 -29 -49 -59 -52 -80 -4 -18 -11 -34 -15 -36 -12 -4 -20
-167 -9 -174 5 -4 7 -17 3 -31 -4 -16 -2 -22 5 -18 5 3 10 13 10 21 0 8 3 14
8 14 4 0 15 28 26 63 10 34 27 87 38 117 10 30 27 85 37 121 10 37 23 73 30
80 14 17 131 250 131 261 0 11 -31 9 -59 -5z"/>
<path d="M6260 6408 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M6287 6335 c10 -48 9 -142 -2 -209 -10 -63 9 -60 47 10 31 55 35 86
13 104 -8 7 -17 27 -21 45 -3 17 -15 45 -25 61 l-20 29 8 -40z"/>
<path d="M2759 6337 c-9 -7 -41 -34 -71 -61 l-56 -49 4 -61 c5 -71 22 -96 45
-69 25 30 139 219 139 232 0 6 -4 11 -9 11 -5 0 -15 2 -23 5 -7 2 -20 -1 -29
-8z"/>
<path d="M6026 6295 c-12 -9 -42 -15 -72 -15 l-52 0 -7 -40 c-10 -62 8 -99 63
-129 28 -15 29 1 3 52 -23 46 -19 49 75 62 l72 10 -15 38 c-16 40 -35 46 -67
22z"/>
<path d="M6151 6055 c-18 -8 -46 -15 -62 -15 -24 0 -29 -4 -29 -24 0 -18 7
-25 31 -30 41 -8 139 -8 139 0 0 23 -25 84 -34 84 -6 -1 -26 -7 -45 -15z"/>
<path d="M6436 5975 c-3 -9 -6 -24 -6 -33 0 -10 -12 -39 -26 -65 -25 -47 -29
-88 -9 -93 6 -2 25 20 43 49 28 44 32 58 26 92 -8 54 -19 73 -28 50z"/>
<path d="M6070 5886 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M6237 5786 c-79 -13 -74 -34 17 -64 38 -13 70 -21 72 -19 5 4 -16 81
-24 87 -4 3 -33 1 -65 -4z"/>
<path d="M1560 5731 c0 -5 -8 -11 -17 -13 -10 -3 -24 -18 -32 -34 -7 -16 -17
-37 -22 -46 -13 -26 -11 -176 3 -223 6 -22 12 -43 12 -47 2 -22 22 -5 28 24
19 82 68 329 68 338 0 5 -9 10 -20 10 -11 0 -20 -4 -20 -9z"/>
<path d="M6498 5593 c-40 -57 -52 -123 -24 -123 20 0 86 101 86 131 0 53 -23
50 -62 -8z"/>
<path d="M6305 5503 c-35 -9 -38 -21 -13 -45 35 -32 108 -72 108 -59 0 6 -5
11 -10 11 -6 0 -10 6 -9 13 1 6 1 29 0 50 -1 34 -3 37 -28 36 -16 -1 -37 -3
-48 -6z"/>
<path d="M6142 5325 c0 -11 4 -33 8 -50 7 -27 8 -27 8 -5 0 14 -4 36 -8 50 -6
20 -8 21 -8 5z"/>
<path d="M1490 5316 c0 -8 -9 -18 -20 -21 -11 -4 -27 -23 -36 -43 -18 -43 -21
-163 -5 -205 6 -16 11 -39 11 -53 0 -13 4 -24 9 -24 5 0 12 -16 16 -36 3 -20
8 -34 10 -32 8 8 32 216 39 346 4 66 2 82 -10 82 -8 0 -14 -6 -14 -14z"/>
<path d="M6545 5251 c-44 -48 -50 -60 -45 -86 l5 -30 53 49 c42 40 52 55 52
82 0 18 -3 35 -7 37 -5 2 -31 -21 -58 -52z"/>
<path d="M2431 5184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6353 5193 c-7 -2 -13 -11 -13 -19 0 -15 67 -94 80 -94 10 0 0 92
-13 108 -10 12 -32 15 -54 5z"/>
<path d="M2423 5100 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M6172 4984 c-4 -137 -24 -318 -38 -361 -9 -25 -19 -66 -24 -92 -13
-70 -30 -134 -39 -146 -5 -5 -11 -27 -15 -47 -4 -21 -11 -38 -16 -38 -6 0 -10
-9 -10 -21 0 -11 -4 -18 -9 -15 -12 8 -22 -20 -15 -39 4 -8 2 -15 -4 -15 -6 0
-13 -14 -17 -30 -4 -17 -11 -28 -16 -24 -5 3 -9 -3 -9 -13 0 -10 -7 -28 -15
-39 -7 -12 -28 -48 -46 -80 -31 -57 -91 -145 -116 -172 -7 -7 -13 -17 -13 -22
0 -5 -23 -35 -51 -67 -70 -80 -74 -89 -47 -104 20 -11 26 -7 66 37 94 103 227
328 293 494 100 253 158 559 147 780 -4 74 -4 74 -6 14z"/>
<path d="M2422 4920 c-5 -258 65 -563 187 -824 52 -110 119 -228 133 -234 4
-2 8 -8 8 -13 0 -33 210 -280 321 -378 151 -133 368 -274 484 -315 74 -26 489
-106 705 -136 174 -25 217 -23 415 15 144 28 159 29 130 13 -66 -36 -105 -112
-105 -205 0 -42 -5 -51 -54 -90 -8 -7 -27 -13 -41 -13 -14 0 -34 -11 -47 -25
-13 -15 -32 -25 -48 -25 -14 0 -34 -6 -45 -13 -11 -8 -41 -20 -68 -27 -26 -7
-50 -16 -53 -21 -2 -5 20 -15 50 -24 55 -16 56 -16 89 9 19 14 36 23 40 20 3
-3 20 1 39 10 18 8 76 26 128 40 111 28 212 63 293 101 57 26 57 27 36 44 -16
13 -22 32 -26 84 -5 58 -2 73 22 122 36 74 74 111 179 172 20 12 20 13 1 13
-11 0 -31 -9 -45 -20 -14 -11 -29 -20 -35 -20 -5 0 -25 -15 -43 -34 -32 -34
-80 -52 -95 -37 -8 8 50 41 72 41 14 0 14 35 1 59 -6 13 -17 17 -35 14 -14 -3
-25 -9 -25 -13 0 -11 -233 -85 -330 -105 -118 -25 -232 -35 -398 -35 -143 0
-184 5 -359 41 -29 6 -56 10 -60 11 -5 0 -12 3 -18 8 -5 4 -26 11 -45 15 -19
4 -48 13 -65 20 -16 7 -46 18 -65 25 -44 15 -179 81 -245 120 -27 16 -61 35
-75 42 -14 7 -32 18 -40 25 -197 154 -302 251 -404 373 -48 58 -116 157 -116
169 0 6 -4 11 -8 11 -5 0 -17 17 -28 37 -10 21 -24 43 -31 50 -6 7 -24 39 -40
70 -15 32 -31 59 -35 61 -4 2 -4 11 -1 19 5 12 2 15 -8 11 -10 -4 -14 3 -15
24 -1 16 -10 35 -20 43 -10 8 -15 15 -11 15 5 0 -1 17 -12 38 -26 49 -44 109
-36 122 3 5 2 10 -3 10 -5 0 -13 10 -17 23 -42 140 -73 336 -78 497 -3 72 -3
67 -5 -30z"/>
<path d="M6576 4925 c-21 -19 -44 -35 -51 -35 -12 0 -30 -59 -21 -68 10 -9 55
9 84 33 22 18 31 36 34 65 2 22 1 40 -2 40 -3 0 -23 -16 -44 -35z"/>
<path d="M1458 4863 c-10 -3 -18 -13 -18 -23 0 -10 -5 -20 -12 -22 -11 -4 -14
-107 -4 -154 6 -30 58 -138 68 -142 14 -6 9 -97 -7 -118 -8 -10 -15 -33 -15
-50 0 -38 39 -160 63 -197 9 -14 17 -29 17 -32 0 -10 59 -70 63 -65 6 7 -7 88
-37 220 -31 138 -33 147 -42 161 -4 6 -8 58 -9 115 -2 57 -6 107 -9 112 -3 5
-10 49 -15 97 -11 94 -16 104 -43 98z"/>
<path d="M6378 4853 c-23 -6 -23 -8 8 -60 30 -53 44 -49 33 9 -4 24 -8 46 -9
51 0 7 -4 7 -32 0z"/>
<path d="M6567 4590 c-16 -16 -35 -30 -42 -30 -16 0 -45 -38 -45 -57 0 -20 25
-16 80 12 47 24 50 27 50 65 0 22 -3 40 -8 40 -4 0 -20 -13 -35 -30z"/>
<path d="M6295 4575 c22 -19 36 -35 31 -35 -5 0 -4 -6 1 -12 6 -7 16 -34 23
-61 7 -26 17 -50 21 -53 16 -10 9 74 -7 91 -9 9 -16 27 -16 41 0 14 -6 24 -14
24 -8 0 -14 4 -14 8 0 9 -44 32 -58 32 -4 -1 11 -16 33 -35z"/>
<path d="M6190 4325 c0 -18 55 -121 70 -130 5 -3 20 9 34 27 25 32 25 33 5 40
-11 5 -40 24 -65 44 -35 29 -44 33 -44 19z"/>
<path d="M6530 4272 c-19 -16 -51 -36 -71 -45 -28 -13 -38 -24 -41 -48 -5 -30
-5 -31 31 -23 20 4 46 8 59 8 16 1 25 9 29 26 3 14 10 30 15 37 6 7 8 18 5 25
-2 7 0 21 5 31 12 23 9 22 -32 -11z"/>
<path d="M6277 4163 c-11 -10 -8 -63 3 -63 6 0 10 16 10 35 0 36 -2 40 -13 28z"/>
<path d="M2268 4115 c-4 -4 -3 -15 2 -24 5 -9 12 -25 15 -36 4 -11 9 -15 12
-9 5 16 -20 77 -29 69z"/>
<path d="M6083 4034 c3 -16 17 -67 31 -113 15 -46 24 -93 20 -103 -3 -10 -1
-18 5 -18 6 0 11 6 11 13 0 6 11 33 25 59 14 26 25 52 25 58 0 6 -28 39 -61
72 -57 56 -61 59 -56 32z"/>
<path d="M1614 3963 c-11 -39 -13 -93 -3 -93 5 0 9 -6 9 -13 0 -31 52 -109
108 -160 12 -11 22 -26 22 -33 0 -7 5 -16 11 -20 19 -11 4 43 -41 156 -78 195
-91 215 -106 163z"/>
<path d="M6440 3952 c-19 -19 -58 -46 -87 -60 -73 -36 -72 -58 3 -64 33 -3 60
-11 65 -19 6 -10 9 -7 9 12 0 14 4 29 8 35 11 12 46 123 41 127 -2 2 -20 -12
-39 -31z"/>
<path d="M5936 3748 c2 -18 8 -75 14 -127 6 -52 12 -96 14 -98 4 -4 59 66 82
103 7 11 15 16 19 12 4 -4 -5 -29 -21 -55 -16 -26 -29 -52 -29 -56 0 -4 -7
-12 -15 -17 -8 -5 -12 -11 -9 -14 9 -10 109 91 109 111 0 35 -24 73 -46 73
-20 0 -94 73 -94 93 0 4 -6 7 -14 7 -10 0 -13 -10 -10 -32z"/>
<path d="M2420 3762 c0 -5 7 -15 15 -22 8 -7 15 -18 15 -24 0 -11 35 -46 46
-46 3 0 2 6 -2 12 -5 7 -9 19 -10 28 -8 48 -12 55 -37 58 -15 2 -27 -1 -27 -6z"/>
<path d="M6381 3727 c-1 -17 -33 -59 -81 -104 -24 -22 -100 -63 -119 -63 -10
0 -22 -40 -15 -51 2 -4 18 -10 34 -14 16 -4 32 -11 35 -16 8 -12 -49 -94 -96
-141 -21 -20 -60 -47 -87 -58 -27 -12 -59 -34 -71 -50 -21 -30 -21 -30 -1 -42
32 -20 24 -43 -33 -100 -54 -55 -159 -116 -189 -110 -4 1 -5 -4 -2 -12 3 -7
-2 -20 -10 -29 -9 -9 -16 -26 -16 -39 0 -33 -95 -118 -131 -118 -7 0 -19 -7
-28 -15 -15 -13 -60 -52 -146 -127 -22 -20 -53 -43 -70 -52 -70 -38 -101 -59
-125 -81 -24 -22 -147 -93 -200 -116 -14 -5 -50 -22 -80 -36 -189 -87 -370
-153 -424 -153 -12 0 -33 -7 -48 -14 -16 -8 -36 -15 -45 -14 -10 0 -65 -8
-123 -18 -96 -17 -102 -19 -65 -25 99 -17 375 17 550 66 33 10 78 21 99 27 85
20 356 142 452 203 30 19 57 35 60 35 2 0 10 4 17 10 7 5 48 37 92 71 101 78
120 95 219 196 108 108 198 209 213 238 7 12 19 25 27 28 8 3 24 24 36 47 11
22 29 49 38 58 19 19 72 98 72 107 0 3 8 15 18 26 11 10 25 32 33 47 7 15 22
39 32 55 10 15 38 67 63 115 24 48 53 103 64 122 11 19 20 43 20 53 0 10 9 31
21 46 12 16 19 37 16 47 -4 17 -5 17 -6 1z"/>
<path d="M2498 3654 c-4 -3 -2 -19 4 -35 11 -32 35 -34 30 -3 -4 23 -26 47
-34 38z"/>
<path d="M1794 3555 c-8 -19 4 -85 15 -85 5 0 8 -6 7 -13 -2 -23 113 -157 135
-157 15 0 10 20 -11 47 -11 14 -20 32 -20 39 0 7 -10 29 -22 47 -25 38 -57 91
-73 119 -12 22 -24 23 -31 3z"/>
<path d="M5753 3528 c-6 -7 -13 -62 -16 -123 -3 -60 -10 -122 -16 -137 -9 -25
-8 -28 9 -28 10 0 25 8 32 18 27 38 79 94 84 89 3 -2 -1 -11 -8 -18 -41 -43
-78 -91 -78 -100 0 -20 94 -4 128 21 35 26 38 36 17 64 -8 10 -12 23 -9 28 3
5 -3 12 -15 15 -12 3 -21 13 -21 23 0 9 -18 36 -40 59 -22 23 -40 48 -40 56 0
8 -5 15 -11 15 -6 0 -9 7 -5 15 7 18 2 19 -11 3z"/>
<path d="M2670 3480 c-8 -5 -25 -10 -37 -10 -13 0 -23 -4 -23 -9 0 -5 28 -39
63 -76 34 -37 68 -76 75 -86 17 -24 32 -24 32 -1 0 31 -21 93 -30 87 -5 -3
-16 20 -25 50 -15 55 -26 64 -55 45z"/>
<path d="M5517 3313 c-3 -5 -8 -40 -12 -78 -6 -61 -23 -126 -50 -187 -13 -32
29 -20 84 23 29 23 54 39 57 37 2 -3 -12 -18 -33 -34 -93 -70 -103 -94 -37
-94 19 0 56 -5 81 -12 40 -10 47 -9 55 6 5 9 13 14 19 11 10 -7 4 10 -22 62
-11 21 -16 46 -13 60 5 17 2 23 -11 23 -10 0 -15 5 -12 14 3 8 -5 18 -19 25
-26 12 -63 81 -64 118 0 23 -15 39 -23 26z"/>
<path d="M5320 3259 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2949 3224 c-7 -8 -26 -22 -43 -31 l-30 -16 41 -36 c180 -158 191
-163 154 -63 -11 33 -21 71 -21 86 0 19 -6 29 -19 33 -11 3 -21 13 -24 24 -6
23 -40 25 -58 3z"/>
<path d="M5254 3202 c7 -12 -16 -142 -37 -202 -6 -19 -25 -57 -41 -83 -37 -63
-23 -76 43 -41 132 72 140 79 112 93 -22 12 -50 67 -50 98 0 10 -1 39 -3 65
-2 25 0 44 3 42 4 -2 9 3 13 11 3 8 1 15 -3 15 -5 0 -17 3 -27 7 -12 4 -15 3
-10 -5z"/>
<path d="M2048 3137 c-4 -22 0 -32 19 -44 13 -9 22 -22 19 -29 -3 -7 4 -16 15
-19 10 -4 19 -11 19 -16 0 -5 7 -9 15 -9 26 0 12 40 -27 82 -10 10 -18 23 -18
28 0 6 -6 10 -14 10 -8 0 -16 6 -19 13 -2 6 -6 -1 -9 -16z"/>
<path d="M3276 3043 c-25 -46 -63 -86 -74 -79 -19 11 -22 -11 -4 -25 30 -25
201 -103 209 -96 3 4 -2 24 -14 45 -11 20 -25 57 -32 82 -7 25 -16 56 -19 70
-11 37 -47 38 -66 3z"/>
<path d="M3610 2945 c0 -28 -32 -110 -51 -131 -17 -18 -18 -23 -5 -33 14 -12
98 -41 117 -41 19 0 28 38 18 76 -19 75 -33 119 -42 132 -13 17 -37 15 -37 -3z"/>
<path d="M5253 2876 c-85 -42 -100 -60 -60 -71 12 -3 62 -25 111 -48 60 -27
91 -37 94 -29 2 7 16 12 30 13 15 0 21 3 13 6 -21 8 -69 64 -82 97 -9 21 -9
33 -1 46 11 19 6 31 -12 29 -6 -1 -48 -20 -93 -43z"/>
<path d="M4975 2757 c-22 -5 -53 -15 -70 -22 -16 -7 -41 -16 -55 -20 -14 -4
-44 -18 -67 -31 -59 -34 -58 -62 7 -134 l49 -55 98 -1 c90 -1 102 1 151 28 29
15 59 28 67 28 29 0 14 18 -22 27 -55 13 -98 77 -97 144 0 29 -4 48 -10 48 -6
-1 -29 -6 -51 -12z"/>
<path d="M3935 2630 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M3912 2589 c0 -16 4 -27 9 -24 5 4 12 0 15 -9 3 -8 17 -14 34 -14 16
1 35 -4 42 -9 7 -6 25 -13 41 -17 24 -6 27 -11 27 -46 0 -22 -3 -40 -7 -40
-15 0 -45 -78 -38 -99 3 -11 9 -21 13 -21 4 0 26 18 50 40 24 22 45 40 48 40
3 0 12 7 20 15 19 18 110 21 116 3 7 -19 36 -1 53 33 11 21 13 35 7 41 -7 7
-5 21 4 43 20 50 3 58 -119 60 -108 2 -184 12 -204 27 -7 6 -15 3 -21 -8 -8
-14 -10 -14 -15 -1 -3 9 -19 15 -40 15 -31 0 -34 -3 -35 -29z"/>
<path d="M4357 2392 c-19 -21 -23 -56 -5 -50 19 7 38 42 29 56 -6 10 -11 8
-24 -6z"/>
<path d="M3668 2393 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4625 2070 c-4 -6 7 -10 27 -10 25 0 29 3 18 10 -19 13 -37 13 -45 0z"/>
<path d="M4480 2060 c0 -5 14 -10 31 -10 17 0 28 4 24 10 -3 6 -17 10 -31 10
-13 0 -24 -4 -24 -10z"/>
<path d="M4568 2063 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5481 2056 c11 -11 65 -15 79 -6 11 7 1 10 -34 10 -27 0 -47 -2 -45
-4z"/>
<path d="M1547 1873 c-4 -3 -7 -262 -7 -575 l0 -568 2948 0 c1621 0 2957 -3
2970 -6 l22 -6 0 581 0 581 -2963 0 c-1630 0 -2967 -3 -2970 -7z m1915 -565
l3 -273 143 -3 142 -3 0 -34 0 -35 -187 2 -188 3 -3 295 c-1 162 0 300 3 307
2 8 21 13 45 13 l40 0 2 -272z m458 -38 l0 -310 -40 0 -40 0 0 310 0 310 40 0
40 0 0 -310z m528 274 l-3 -36 -145 -1 -145 -2 -3 -102 -3 -103 131 0 130 0 0
-35 0 -35 -130 0 -130 0 0 -136 0 -135 -40 3 -41 3 0 298 c1 163 1 302 1 307
0 6 70 10 191 10 l190 0 -3 -36z m276 -11 c10 -27 29 -77 41 -113 13 -36 57
-152 98 -257 41 -106 72 -195 69 -198 -3 -3 -22 -5 -43 -5 l-38 0 -28 80 -28
80 -126 0 -126 0 -15 -37 c-9 -21 -22 -56 -30 -78 -13 -37 -17 -40 -56 -43
-23 -2 -42 1 -42 7 0 5 16 51 36 103 20 51 66 174 104 273 38 99 73 192 79
208 9 23 16 27 49 27 36 0 39 -2 56 -47z m762 32 c63 -19 125 -71 155 -132 21
-42 24 -62 24 -163 0 -101 -3 -121 -24 -163 -30 -61 -92 -113 -155 -132 -30
-9 -91 -15 -158 -15 l-108 0 0 310 0 310 108 0 c67 0 128 -6 158 -15z m640
-284 c63 -162 114 -301 114 -308 0 -8 -13 -13 -39 -13 l-40 0 -29 78 -30 77
-128 0 -127 0 -29 -77 -30 -78 -39 0 c-21 0 -39 4 -39 10 0 5 24 73 54 152
120 320 166 440 172 449 3 6 22 9 41 7 l36 -3 113 -294z m562 262 l3 -32 -98
-3 -98 -3 -3 -272 -2 -273 -40 0 c-25 0 -40 5 -41 13 0 6 0 122 1 257 1 135
-2 253 -6 263 -5 14 -21 17 -100 17 l-94 0 0 35 0 35 238 -2 237 -3 3 -32z
m312 -13 c10 -25 32 -81 49 -125 17 -44 60 -156 96 -248 36 -92 65 -175 65
-183 0 -12 -10 -14 -41 -12 l-41 3 -27 75 -27 75 -128 0 -128 0 -28 -75 -27
-75 -42 -3 c-46 -3 -48 0 -27 55 23 61 127 334 148 388 11 28 28 74 38 103 26
72 27 73 67 70 31 -3 37 -8 53 -48z m-5244 0 c1 -19 2 -87 3 -150 l1 -115 53
56 c28 30 92 99 140 152 80 88 92 97 124 97 l35 0 -58 -67 c-55 -64 -99 -113
-151 -170 -13 -13 -23 -26 -23 -27 0 -2 20 -30 45 -62 24 -33 66 -88 92 -122
26 -35 53 -69 58 -75 20 -22 45 -61 45 -70 0 -4 -14 -7 -32 -5 -27 3 -42 16
-100 93 -114 151 -142 185 -153 185 -6 0 -26 -16 -44 -34 l-34 -35 0 -105 c0
-95 -2 -106 -18 -106 -50 0 -49 -8 -49 299 0 158 3 291 7 295 4 4 19 6 33 4
21 -3 25 -8 26 -38z m538 24 c3 -9 6 -69 6 -133 l0 -118 158 1 157 1 3 133 3
132 29 0 c25 0 30 -5 35 -32 4 -18 5 -152 3 -298 l-3 -264 -32 -3 -33 -3 0
134 c0 95 -4 136 -12 143 -7 5 -78 9 -158 9 l-145 -1 -5 -140 -5 -140 -32 -3
-33 -3 0 300 0 301 29 0 c17 0 31 -6 35 -16z m779 -21 c9 -21 27 -67 40 -103
98 -258 151 -399 163 -425 13 -29 12 -30 -13 -33 -28 -4 -62 28 -63 58 0 8 -9
34 -20 58 l-19 42 -130 0 -130 0 -14 -27 c-8 -16 -22 -52 -32 -81 -15 -46 -20
-52 -41 -49 -13 1 -26 6 -29 9 -3 3 22 77 55 164 34 88 73 191 87 229 35 98
64 168 75 183 21 25 54 14 71 -25z"/>
<path d="M4617 1331 c-26 -71 -47 -132 -47 -135 0 -3 43 -6 95 -6 62 0 95 4
95 11 0 13 -81 241 -90 251 -3 4 -27 -50 -53 -121z"/>
<path d="M5317 1503 c-4 -3 -7 -111 -7 -240 l0 -233 58 0 c153 2 212 70 212
245 0 133 -30 190 -117 221 -40 14 -135 19 -146 7z"/>
<path d="M5927 1334 c-26 -69 -47 -130 -47 -135 0 -5 43 -9 95 -9 52 0 95 4
95 8 0 9 -89 254 -94 259 -2 2 -24 -53 -49 -123z"/>
<path d="M6897 1334 c-26 -71 -47 -132 -47 -136 0 -5 43 -8 95 -8 55 0 95 4
95 10 0 10 -63 185 -84 234 -12 28 -13 26 -59 -100z"/>
<path d="M3009 1434 c-11 -27 -24 -58 -29 -69 -26 -62 -60 -169 -55 -173 7 -8
141 -11 183 -4 25 3 32 9 28 21 -16 51 -96 264 -100 269 -3 3 -15 -17 -27 -44z"/>
<path d="M0 9 c0 -5 1891 -9 4385 -9 2412 0 4385 4 4385 8 0 5 -1973 9 -4385
9 -2494 0 -4385 -3 -4385 -8z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
